import React from "react";

function Footer() {
    return (
        <div class="footer">
            <p>&copy; 2023 Mark Stevenson</p>
            <p>Designed and built by Mark Stevenson</p>
        </div>
    );
}

export default Footer;