import React from 'react';
import logo from './logo.svg';
import './css/App.css';
import NavBar from "./components/navbar.component";
import Writing from "./components/writing.component";
import Footer from "./components/footer.component";

function App() {
  return (
    <div className="main">
     {/* <Router>
     <NavBar /> */}
     {/* <Route path="/writing" exact component={Writing} /> */}
      <div className="content">
        <h1>About</h1>
          <p>Hello! Welcome to my website. I am Mark! I'm interested in how the world works and using data to figure that out.</p>
          {/* <p>I write and analyse data about business and economics at <a href="https://businessandeconomics.substack.com/" target="_blank">Thoughts on Business & Economics</a>, and I write about algorithms and statistics in business at <a href="https://algorithmicworld.substack.com/" target="_blank">Algorithmic World.</a></p> */}
        <h1>CV</h1>
          <p>I've worked in data science at fintech and sports streaming companies, and I've also been a management consultant.</p>
        <h1>Contact</h1>
          <p>You can find me on <a href="https://www.linkedin.com/in/mark-stevenson/" target="_blank">LinkedIn</a>, or contact me at mark[at]markrstevenson[dot]com.
            If you have any opportunities that may interest me, please email.</p>
        <h1>Writing</h1>
         <p>I write every second Friday at <a href="https://buildthefuture.beehiiv.com/" target="_blank">Build the Future</a>. A quiet little corner of the internet where we look at tech and building the future, with a few laughs along the way.
          Hopefully you'll find some of the articles interesting and maybe even fun. Have a read of Paul Graham's <a href="http://www.paulgraham.com/writing44.html" target="_blank">essay</a>. I agree with him that writing is critically important.</p>
          <ol reversed>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/innovation" target="_blank">We're In for Innovation</a> 19th July 2024</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/exports-imports-tech" target="_blank">Exports, Imports and Tech</a> 5th July 2024</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/car-industry" target="_blank">The Wheels of the Car Industry</a> 21st June 2024</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/big-business-contradiction" target="_blank">The Big Business Contradiction</a> 7th June 2024</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/hype-train" target="_blank">The Hype Train Has Left the Station</a> 24th May 2024</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/markets-and-trade" target="_blank">On Markets and Trade</a> 10th May 2024</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/flying-high" target="_blank">Flying High</a> 26th April 2024</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/a-vc-value-investor-quant" target="_blank">A VC, A Value Investor and a Quant Walk Into A Bar</a> 12th April 2024</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/mach-10-economic-growth" target="_blank">We’re Aiming for Mach 10 Economic Growth</a> 29th March 2024</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/essence-of-capitalism" target="_blank">The Essence of Capitalism</a> 15th March 2024</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/now-thats-what-i-call-investing" target="_blank">Now That's What I Call Investing</a> 1st March 2024</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/infrastructure-please" target="_blank">Infrastructure, Please</a> 16th February 2024</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/just-innovate-build" target="_blank">Just Innovate and Build</a> 2nd February 2024</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/silicon-building-blocks-technology" target="_blank">The Silicon Building Block of Technology</a> 19th January 2024</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/irobot" target="_blank">I Spy, iRobot</a> 5th January 2024</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/an-economy-builder" target="_blank">An Economy Builder</a> 22nd December 2023</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/build-a-great-product" target="_blank">Build A Great Product</a> 8th December 2023</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/ride-the-wave" target="_blank">Ride the Wave</a> 24th November 2023</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/new-york-psyche" target="_blank">The Growth Psyche</a> 10th November 2023</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/select-database" target="_blank">SELECT Database</a> 27th October 2023</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/market-path-ai" target="_blank">The Market Path of AI</a> 13th October 2023</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/choose-your-fighter" target="_blank">Choose Your Fighter</a> 29th September 2023</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/in-for-a-penny-in-for-a-pound" target="_blank">In For a Penny, In For a Pound</a> 15th September 2023</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/so-goes-the-world" target="_blank">So Goes The World</a> 1st September 2023</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/ooh-aah-optimisation" target="_blank">Ooh Aah Optimisation</a> 18th August 2023</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/ais-gonna-be-alright" target="_blank">Don’t Worry About a Thing, AI's Gonna Be Alright</a> 4th August 2023</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/unpredictable-dance-economic-empires" target="_blank">The Unpredictable Dance of Economic Empires</a> 21st July 2023</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/supply-chain-ahoy" target="_blank">Supply Chain, Ahoy!</a> 7th July 2023</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/hard-can" target="_blank">How Hard Can It Be?</a> 23rd June 2023</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/interconnected-global-economy" target="_blank">The Interconnected Global Economy</a> 9th June 2023</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/tracks-global-trade" target="_blank">The Tracks of Global Trade</a> 26th May 2023</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/bell-labs-changing-world" target="_blank">Bell Labs and Changing the World</a> 12th May 2023</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/living-scifi" target="_blank">We're Living Through Sci-Fi</a> 28th April 2023</p></li>
            <li><p><a href="https://buildthefuture.beehiiv.com/p/arc-technology" target="_blank">The Arc of Technology</a> 14th April 2023</p></li>
          </ol>

          {/* <p>I write at <a href="https://businessandeconomics.substack.com/" target="_blank">Thoughts on Business & Economics</a> and <a href="https://algorithmicworld.substack.com/" target="_blank">Algorithmic World</a>. I release a new post every Thursday, alternating between <i>Thoughts on Business and Economics</i> and <i>Algorithmic World</i>.</p>
          <ol reversed>
            <li><p><a href="https://algorithmicworld.substack.com/p/a-few-thoughts-on-ai" target="_blank">A Few Thoughts on AI</a>: <i>AI, Day One</i> (<a href="https://algorithmicworld.substack.com/" target="_blank">Algorithmic World</a>, 23rd March 2023)</p></li>
            <li><p><a href="https://businessandeconomics.substack.com/p/shift-economic-growth" target="_blank">Shift Economic Growth</a>: <i>Run program, 'grow_economy(change = Normal(3, 1))'.</i> (<a href="https://businessandeconomics.substack.com/" target="_blank">Thoughts on Business & Economics</a>, 16th March 2023)</p></li>
            <li><p><a href="https://algorithmicworld.substack.com/p/around-the-world-in-80-algos" target="_blank">Around The World In 80 Algos</a>: <i>We’ve perhaps become normalised to something extraordinary.</i> (<a href="https://algorithmicworld.substack.com/" target="_blank">Algorithmic World</a>, 9th March 2023)</p></li>
            <li><p><a href="https://businessandeconomics.substack.com/p/-compounding-innovation-a-wonder-of-the-world" target="_blank">Compunding Innovation, A Wonder of the World</a>: <i>The great thing about great innovation is that it leads to more great things. Innovation compounds.</i> (<a href="https://businessandeconomics.substack.com/" target="_blank">Thoughts on Business & Economics</a>, 2nd March 2023)</p></li>
            <li><p><a href="https://businessandeconomics.substack.com/p/why-i-write" target="_blank">Why I Write</a>: <i>It generates ideas.</i> (<a href="https://businessandeconomics.substack.com/" target="_blank">Thoughts on Business & Economics</a> and <a href="https://algorithmicworld.substack.com/" target="_blank">Algorithmic World</a>, 23rd February 2023)</p></li>
            <li><p><a href="https://algorithmicworld.substack.com/p/an-algorithmic-world" target="_blank">An Algorithmic World</a>: <i>We should reduce a company's activities into mathematical components and optimise each part through algorithms.</i> (<a href="https://algorithmicworld.substack.com/" target="_blank">Algorithmic World</a>, 11th January 2023)</p></li>
            <li><p><a href="https://businessandeconomics.substack.com/p/a-bit-of-fun-with-chatgpt" target="_blank">A Bit of Fun with ChatGPT</a>: <i>A Q&A random walk with ChatGPT.</i> (<a href="https://businessandeconomics.substack.com/" target="_blank">Thoughts on Business & Economics</a>, 23rd December 2022)</p></li>
            <li><p><a href="https://businessandeconomics.substack.com/p/the-korean-economy-and-the-chaebols" target="_blank">The Korean Economy and the Chaebols</a>: <i>A quick tour through the Korean economy and the incredible growth in prosperity this country has seen.</i> (<a href="https://businessandeconomics.substack.com/" target="_blank">Thoughts on Business & Economics</a>, 3rd December 2022)</p></li>
            <li><p><a href="https://businessandeconomics.substack.com/p/the-inevitable-fall-of-business-empires" target="_blank">The Inevitable Fall of Business Empires</a>: <i>In the ever changing business landscape, finding a business model which sustains a competitive advantage over many decades is challenging.</i> (<a href="https://businessandeconomics.substack.com/" target="_blank">Thoughts on Business & Economics</a>, 6th November 2022)</p></li>
            <li><p><a href="https://businessandeconomics.substack.com/p/in-search-of-the-perfect-business" target="_blank">In Search of the Perfect Business</a>: <i>A diversified conglomerate is enduringly appealing.</i> (<a href="https://businessandeconomics.substack.com/" target="_blank">Thoughts on Business & Economics</a>, 25th October 2022)</p></li>
            <li><p><a href="https://businessandeconomics.substack.com/p/build-new-economies" target="_blank">Build New Economies</a>: <i>We need new companies, new infrastructure, new markets, and new economies. We need a surge of economic growth.</i> (<a href="https://businessandeconomics.substack.com/" target="_blank">Thoughts on Business & Economics</a>, 12th July 2022)</p></li>
          </ol> */}
        <h1>Media</h1>
        <p><a href="https://open.spotify.com/episode/2ZeCGKjC3cBry13gOzAqtk?si=fc013b3a05d7486c" target="_blank">Would You Data Scientist?</a> podcast episode (May 2022)</p>
        <h1>Books</h1>
          A collection of books which are excellent:
          <p><em>The 7 Habits of Highly Effective People: Powerful Lessons in Personal Change</em> by Stephen Covey. A personal development classic, and still as useful as ever.</p>
          <p><em>Fool's Gold</em> by Gillian Tett. The definitive history of the development of credit default swaps, collateralized debt obligation and the 2008 financial crisis. The book makes you feel as if you were on Wall Street before and during the crash.</p>
          <p><em>Man's Search for Meaning</em> by Viktor Frankl. Harrowing and engaging in equal measure. We always have a choice.</p>
          <p><em>The Big Short: Inside the Doomsday Machine</em> by Michael Lewis. The incredible story of those who predicted and bet big on the US subprime mortgage crisis. The movie of the same name may be one of the best movies ever made.</p>
          <p><em>The Man from the Future: The Visionary Life of John von Neumann</em> by Ananyo Bhattacharya. John von Neumann may be the smartest person who ever lived. This is his story.</p>
          <p><em>The Smartest Guys in the Room: The Amazing Rise and Scandalous Fall of Enron</em> by Bethany McLean. A riveting story of corporate fraud, and how asking the simple question "how do you make money" turned out to be profound.</p>
          <p><em>When Genius Failed: The Rise and Fall of Long Term Capital Management</em> by Roger Lowenstein. This is a cautionary tale of how the market can stay irrational, or unpredictable, longer than you can stay solvent.</p>
          <p><em>The Signal and the Noise: Why So Many Predictions Fail—But Some Don't</em> by Nate Silver. The seminal insight into how to make predictions.</p>
          <p><em>Chernobyl: The History of a Nuclear Catastrophe</em> by Serhii Plokhy. The true story of how bad management, bad economic systems and bad incentives led to a technological, ecological and human catastrophe.</p>
          <p><em>The Innovators: How a Group of Hackers, Geniuses and Geeks Created the Digital Revolution</em> by Walter Isaacson. The complete history of computing and the digital age, from Babbage's Counting Machine to the modern technology giants.</p>
          <p><em>The Snowball: Warren Buffett and the Business of Life</em> by Alice Schroeder. The story of the greatest investor and CEO of all time. Endless business advice and wisdom is contained within the pages of this classic.</p>
          <p><em>Command and Control: Nuclear Weapons, the Damascus Accident, and the Illusion of Safety</em> by Eric Schlosser. The book explains how we've narrowly avoided disaster during the nuclear age.</p>
          <p><em>Algorithms to Live By</em> by Brian Christian and Tom Griffiths. If you're an optimisation geek you should read this to optimise your life. If you are not an optimisation geek, you should read this to understand why you should become one.</p>
          <p><em>The Box: How the Shipping Container Made the World Smaller and the World Economy Bigger</em> by Marc Levinson. A good book on an underappreciated subject. We've got the humble shipping container to thank for our global supply chain, and a fair chunk of economic growth.</p>
          <p><em>Chip War: The Fight for the World's Most Critical Technology</em> by Chris Miller. The definitive book on the most important technology in the entire world right now.</p>
          <p><em>Zero to One: Notes on Startups, or How to Build the Future</em> by Blake Masters and Peter Thiel. Thought provoking and interesting in equal measure.</p>
        <h1>Documentaries</h1>
          A few documentaries which are excellent:
          <p><em>Becoming Warren Buffett</em>. The documentary which dives into Warren Buffett, and Berkshire Hathaway.</p>
          <p><em>Enron: The Smartest Guys in the Room</em>. The truth is stranger than fiction. If ever there was a business story which proves the saying, it's Enron. This is a fabulous and eye opening documentary.</p>
          <p><em>The World's Greatest Money Maker</em>. Another documentary on Warren Buffett, but equally as good. The interviews take place just after the 2008 financial crisis, but are timeless. It also includes some classic Charlie Munger quotes.</p>
          <p><em>American Factory</em>. The story of Chinese company, Fuyao, taking over an abandoned General Motors Factory in Ohio.</p>
          <p><em>Return to Space</em>. The story of SpaceX, and the birth of commercial space travel.</p>
          <p><em>The Big Short</em>. Rarely are a book and a movie equally good. This is one of those occasions.</p>
          <p><em>Simon Reeve</em> documentaries. Any of them: they'll open your eyes to a magical, crazy and fascinating world.</p>
      </div>
      {/* </Router> */}
      <Footer />
    </div>
  )
}

export default App;
